import type { FC } from 'react';
import { Page } from '@/components/Page.tsx';
import './UiversePage.css';

export const UiversePage: FC = () => {
  return (
    <Page>
      <div>
        <h1>Uiverse Samples</h1>
        <p>Here you can explore various Uiverse samples.</p>
        <button>
          <span>Click Me</span>
        </button>
        <div className="terminal-loader">
          <div className="terminal-header">
            <div className="terminal-title">Status</div>
            <div className="terminal-controls">
              <div className="control close"></div>
              <div className="control minimize"></div>
              <div className="control maximize"></div>
            </div>
          </div>
          <div className="text">Loading...</div>
        </div>
      </div>
    </Page>
  );
};
